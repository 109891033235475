<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.screen.editScreen')"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <Form ref="formValidate" class="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem :label="$t('pages.screen.screenID')">
                {{ editInfo.serial }}
            </FormItem>
            <FormItem :label="$t('pages.screen.name')" prop="name">
                <Input v-model.trim="formValidate.name" :placeholder="$t('pages.screen.inputScreenName')"></Input>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            editInfo: {
                default: () => {
                    return {}
                }
            },
            department_id: {
                default: null
            },
            is_org: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    name: ''
                }
            }
        },
        computed: {
            ruleValidate() {
                return {
                    name: [
                        { required: true, message: this.$t('pages.screen.inputScreenName'), trigger: 'blur' },
                        { type: 'string', max: 32, message: this.$t('common.upTo32Characters'), trigger: 'blur' }
                    ]
                }
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.formValidate.name = this.editInfo.name
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$emit('cancel')
                this.$refs['formValidate'].resetFields();
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let params = {
                            name: this.formValidate.name,
                            dir: this.editInfo.dir,
                            department_id: this.department_id
                        }
                        this.$api.put(`terminals/${this.editInfo.rowid}`, params).then(() => {
                            this.$Message.success(this.$t('message.success'));
                            this.$emit('confirm')
                            this.cancel()
                        }).catch(() => {}).finally(() => {
                            this.loading = false
                        })
                    }
                })
            }
        },
        created() {}
    }
</script>
<style scoped>
.formValidate {
    width: 60%;
}
</style>