<template>
    <Modal
        class="wrap"
        v-model="modal"
        width="900"
        title=""
        footer-hide
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <div slot="header">
            <span class="modal-header">{{ $t('pages.screen.repeatersMag') }}</span>
            <span>（{{ $t('pages.screen.repeaterTip') }}）</span>
        </div>
        <div class="action-bar">
            <Dropdown class="dropdown" transfer v-if="is_org" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList" />
                <Button type="text">
                    {{ $t('navigation.structure') }}
                    <div v-if="nodeList.length" class="tree line-overflow">
                        <span>:</span>
                        <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                            <span v-if="index !== nodeList.length - 1">,</span>
                        </span>
                    </div>
                    <Icon type="ios-arrow-down"></Icon>
                </Button>
                <DropdownMenu slot="list">
                    <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                </DropdownMenu>
            </Dropdown>
        </div>
        <Table class="repeaters-table" border :loading="loading" :columns="columns" :data="list">
            <template slot-scope="{ row }" slot="action">
                <span class="action-remove pointer margin-right" @click="handleUnbind(row.rowid)">{{ $t('pages.screen.unbind') }}</span>
                <span v-if="is_org" class="action-transfer pointer" @click="handleTransfer(row.rowid)">{{ $t('pages.screen.transfer2') }}</span>
            </template>
        </Table>
        <Page
            class="page"
            :total="total"
            :current="current"
            :page-size="pageSize"
            show-elevator
            @on-change="nextPage" />
        <transfer-modal
            :show="showTransferModal"
            :rowids="repeaters"
            @cancel="handleTransferCancel"
            @confirm="handleTransferConfirm"></transfer-modal>
    </Modal> 
</template>
<script>
    import { dropdownTree } from '@/mixins/index'
    import util from '@/libs/util.js'
    import transferModal from './transfer'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            transferModal
        },
        props: {
            show: {
                default: false
            },
            is_org: {
                default: ''
            },
            tree_data: {
                default: () => {
                    return []
                }
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                current: 1,
                total: 0,
                pageSize: 10,
                list: [],
                nodeList: [],
                treeList: [],
                data: [],
                showTransferModal: false,
                repeaters: []
            }
        },
        computed: {
            department_id() {
                return this.$store.state.user_tree.department_id
            },
            columns() {
                return [
                    {
                        title: this.$t('pages.screen.repeaterID'),
                        key: 'rid',
                        align: 'center'
                    },
                    {
                        title: this.$t('pages.screen.structureNode'),
                        key: 'department_name',
                        align: 'center'
                    },
                    {
                        title: this.$t('pages.screen.repeaterIP'),
                        key: 'ip',
                        align: 'center'
                    },
                    {
                        title: this.$t('pages.screen.connectedCount'),
                        key: 'bind_terminals_count',
                        align: 'center'
                    },
                    {
                        title: this.$t('pages.screen.registerTime'),
                        key: 'register_time',
                        align: 'center'
                    },
                    {
                        title: this.$t('common.state'),
                        key: 'state',
                        align: 'center'
                    },
                    {
                        title: this.$t('common.action') + '',
                        slot: "action",
                        align: "center"
                    }
                ]
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.data = this.handleRecursive(this.tree_data)
                    this.getData()
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.total = 0
                this.list = []
                this.nodeList =[]
                this.treeList = []
                this.data = []
                this.repeaters = []
                this.$emit('close')
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                if (this.is_org) {
                    let len = this.nodeList.length
                    if (len) {
                        let department_id_list = []
                        this.nodeList.map((item) => {
                            department_id_list.push(item.department_id)
                        })
                        params.department_id_list = JSON.stringify(department_id_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.tree_data, 'department_id')
                        params.department_id_list = JSON.stringify(this.treeList)
                    }
                } else {
                    params.department_id = this.department_id
                    this.getTreeList(this.tree_data, 'department_id')
                    params.bucket_token_list = JSON.stringify(this.treeList)
                }
                this.$api.get('repeaters/search', { params }).then(({ data, total_count, page }) => {
                    if (page === this.current) {
                        data.map((item) => {
                            item.register_time = util.handleTime(item.register_time)
                            return item
                        })
                        this.list = data
                        this.total = total_count
                    }
                }).catch(() => {}).finally(() => {
                    this.loading = false
                })
            },
            handleUnbind(rowid) {
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: this.$t('pages.screen.unbindTip2'),
                    onOk: () => {
                        this.$api.delete(`repeaters/${rowid}`).then(() => {
                            this.$Message.success(this.$t('pages.screen.unbinded'))
                            this.getData()
                        }).catch(() => {})
                    }
                })
            },
            handleTransfer(rowid) {
                this.repeaters = [rowid]
                this.showTransferModal = true
            },
            handleTransferCancel() {
                this.showTransferModal = false
            },
            handleTransferConfirm() {
                this.handleTransferCancel()
                this.getData()
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.modal-header {
    margin-right: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #17233d;
}
.dropdown {
    position: relative;
    margin-left: -15px;
    .close {
        display: none;
    }
    &:hover {
        .close {
            display: block;
            position: absolute;
            right: 0px;
            top: 0px;
        }
    }
    .tree {
        display: inline-block;
        max-width: 100px;
        vertical-align: middle;
    }
}
.repeaters-table {
    margin-bottom: 10px;
}
</style>